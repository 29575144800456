import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "@/clients/supabase";
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

function UpdatePasswordPage() {
  const [isLoading, setIsLoading] = useState<any>(true);
  const navigate = useNavigate();

  async function logout() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.log("Logout failed", error);
      }
    } catch (error) {
      console.error("Logout failed", error);
    }
  }

  useEffect(() => {
    // User clicked on reset password link in their email.
    // User is redirected to /update_password?token=123456&type=recovery
    // Supabase Auth uses the token to creates a session (ie. logs in the user with the token).
    // If the session is created successfully, then we show the page.
    // The user then enters a new password, and Supabase Auth handles the form submission.
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "PASSWORD_RECOVERY" && session) {
        setIsLoading(false);
      } else if (event === "USER_UPDATED") {
        logout();
        // Navigate to login page after password is updated
        navigate("/login");
      }
    });

    return () => {
      // Cleanup the subscription
      subscription.unsubscribe(); 
    };
  }, [navigate]);

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <>
        <div className="flex fixed inset-0 z-[99] w-screen h-screen bg-white">
          <div className="relative top-0 bottom-0 right-0 flex-shrink-0 hidden w-1/3 overflow-hidden bg-cover lg:block">
            <a
              href="#_"
              className="absolute bottom-0 left-0 z-30 inline-flex items-end mb-4 ml-3 font-sans text-2xl font-extrabold text-left text-white no-underline bg-transparent cursor-pointer group focus:no-underline"
            >
              <span className="flex">Makedraft</span>
            </a>
            <div className="absolute inset-0 z-20 w-full h-full opacity-70 bg-gradient-to-t from-black"></div>
            <img
              src="https://images.unsplash.com/photo-1653417335247-1855d21f45d3?q=80&w=1899&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="z-10 object-cover w-full h-full"
            />
          </div>
          <div className="relative flex flex-wrap items-center w-full h-full px-8">
            <div className="relative w-full max-w-sm mx-auto lg:mb-0">
              <div className="relative">
                <h1 className="text-2xl mb-6 text-center">Update Password</h1>

                <Auth
                  supabaseClient={supabase}
                  appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: "black",
                          brandAccent: "gray",
                        },
                      },
                    },
                  }}
                  providers={[]}
                  showLinks={false}
                  view="update_password"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UpdatePasswordPage;
