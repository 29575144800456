import { useEffect, useState, useContext } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from "@/components/ui/button"
import {DownloadIcon} from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"



const UNITY_PLUGIN_SIGNED_URL = import.meta.env.VITE_UNITY_PLUGIN_SIGNED_URL;

function AccountPage() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;

  const instructions = [
    'Download the Unity tool.',
    'In Unity\'s top menu, select "Assets", "Import Package", "Custom Package".',
    'Select the folder you downloaded in step 1.',
    'In Unity\'s top menu, select "Tools", "Makedraft", "Login".',
    'Login with your email and password.',
    'In Unity\'s top menu, select "Tools", "Makedraft", "App".',
    'Create 3D assets!',
  ];

  const [initialized, setInitialized] = useState<boolean>(false);
  async function initializeData() {
    setInitialized(true);
  }

  useEffect(() => {
    if (!initialized) {
      initializeData();
    }
  }, []);

  if (initialized) {
    return (
      <>

      <div className='container grid h-svh flex-col items-center justify-center bg-primary-foreground lg:max-w-none lg:px-0'>
        <div className='mx-auto flex w-full flex-col justify-center space-y-2 lg:p-8'>
        <div className='mb-4 flex items-center justify-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='mr-2 h-6 w-6'
          >
            <path d='M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3' />
          </svg>
          <h1 className='text-xl font-medium'>Makedraft - Text-to-3D Unity Plugin</h1>
        </div>
        <Card>
          <CardHeader className="pb-3">
            <CardTitle className="pb-0">Account</CardTitle>
            <CardDescription>
              <div className="rounded-lg">
                  <span className="text-base">
                    {session.user.email} &nbsp;
                  </span>
                  <span className={`
                    inline-flex items-center rounded-xl px-2 py-1 text-sm text-gray-600 
                    ${session.user.confirmed_at ? 'bg-green-100 ' : 'bg-red-100 ring-red-300'}
                  `}>
                    <span className="text-neutral-700">
                      { session.user.confirmed_at ? 'Confirmed' : 'Unconfirmed' }
                    </span>
                  </span>
              </div>
            </CardDescription>
          </CardHeader>

          <CardContent>
            { !session.user.confirmed_at && (
              <div className="flex space-x-2">
                Please check your email for a confirmation link.
              </div>
            )}
          </CardContent>
        </Card>

        <div className='mb-4 flex items-center justify-center'>
          <Carousel className="w-full max-w-2xl">
            <CarouselContent>
              {Array.from({ length: 7 }).map((_, index) => (
                <CarouselItem key={index}>
                  <div className="p-1">
                    <Card>
                      <CardHeader className="pb-3">
                        <CardTitle className="pb-3">
                          Getting Started - Step {index + 1}
                        </CardTitle>
                        <CardDescription className="text-lg justify-center">
                          {instructions[index]}
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="flex aspect-square items-center justify-center p-6">
                        { index === 0 ? (
                          <Button variant="outline" className="" asChild>
                            <Link to={UNITY_PLUGIN_SIGNED_URL}>
                              <DownloadIcon />&nbsp;
                              Download Unity Tool
                            </Link>
                          </Button>
                        ) : (
                          <img 
                            src={`getting_started_${index + 1}.png`} 
                            alt="Image" 
                            className="rounded-md object-cover" 
                          />
                        )}
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>

        </div>
      </div>

      </>
    );
  }
}

export default AccountPage;

