import { Routes, Route, Outlet } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SessionContext, SessionProvider } from "@/contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { Theme } from "@radix-ui/themes";
import supabase from "@/clients/supabase";
import AccountPage from "@/pages/auth/AccountPage";
import LoginPage from "@/pages/auth/LoginPage";
import SignupPage from "@/pages/auth/SignupPage";
import ForgotPasswordPage from "@/pages/auth/ForgotPasswordPage";
import UpdatePasswordPage from "@/pages/auth/UpdatePasswordPage";


export default function App() {
  return (
    <SessionProvider>
      <Theme>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<SignupPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="forgotten_password" element={<ForgotPasswordPage />} />
            <Route path="update_password" element={<UpdatePasswordPage />} />
          </Route>

          <Route path="dashboard" element={<SecuredLayout />}>
            <Route index element={<AccountPage />} />
          </Route>
        </Routes>
      </Theme>
    </SessionProvider>
  );
}

function Layout() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;

  return (
    <Outlet />
  );
}


function SecuredLayout() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const navigate = useNavigate();

  // Supabase
  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    if (!session) {
      navigate("/login");
    } else {
      return (
        <>
            <Outlet />
        </>
      );
    }
  }
}
